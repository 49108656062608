import debounce from 'lodash/debounce';
import forIn from 'lodash/forIn';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import {
  checkIsActiveEditorDownload,
  updateDataCountSurvey,
  updateIsSurvey,
} from './actions';
// import ExternalLinksHeader from './ExternalLinksHeader';
import JapanSiteNotice from './JapanSiteNotice';
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
  makeSelectToken,
} from '../AuthProvider/selectors';
import ErrorMessageModalContainer from '../ErrorMessageModal';
import {
  makeSelectIsTopBannerVisible,
  makeSelectPatterns,
} from '../HomePage/selectors';
import LoadingContainer from '../Loading';
import {
  makeSelectCategories,
  makeSelectCurrentLang,
  makeSelectIsLocaleInPath,
  makeSelectLink,
  makeSelectServiceType,
} from '../MetadataProvider/selectors';
import {
  makeSelectIsTrial,
  makeSelectTrial,
} from '../RegisterPremiumPage/selectors';
import withI18next from '~/../hocs/_withI18next';
// import GroupWebsites from '~/components/GroupWebsites';
import Footer from '~/components/Layout/Footer';

import MenuHeader from '~/components/Layout/MenuHeader';
// import MenuHeaderCreator from '~/components/Layout/MenuHeaderCreator';
import SearchHeader from '~/components/Layout/SearchHeader';
// import ScrollTop from '~/components/scroll-top/ScrollTop';
import Config from '~/config';
import CustomErrorMessage from '~/containers/CustomErrorMessage';
// import HeaderCountdownDiscount from '~/containers/Layout/HeaderCountdownDiscount';
import RegisteredUsersModal from '~/containers/LogsRegisteredUsers/RegisteredUsersModal';
// import { initCountdown } from '~/containers/PaymentModal/actions';
import { makeSelectIsCountdownActive } from '~/containers/PaymentModal/selectors';
// import { toggleSlider } from '~/containers/PhotoCart/actions';
// import {
//   makeSelectPhotosSlider,
//   makeSelectToggleSlider,
// } from '~/containers/PhotoCart/selectors';
import PhotoSearchModal from '~/containers/SearchPhotoModal/';
import SectionFooterAILandingPage from '~/containers/SectionFooterAILandingPage';
import SectionTotalDownload from '~/containers/SectionTotalDownload';

import { logout } from '~/containers/AuthProvider/actions';
// import LoginModalContainer from '../LoginModal';
import LocalStorage from '~/services/LocalStorage';
// import Banner from '~/components/Banner';
import PopupBanner from '~/components/PopupBanner';
// import { setIsPhotoCartOpen } from '~/containers/Layout/actions';
import { selectLayout } from '~/containers/Layout/selectors';
import PhotoCart from '~/containers/PhotoCart/Loadable';
// import BannerDiscount from '~/components/BannerDiscount';
// import Survey from '~/components/Survey';
import I18nRouter from '~/components/I18nRouter';
import { updateShowFilterOnHeader } from '~/containers/Layout/actions';
import { closeAllFilter } from '~/containers/Search/actions';
import { searchPhoto } from '~/containers/SearchResultPage/actions';
import { makeSelectQuery } from '~/containers/SearchResultPage/selectors';
import themeVariables from '~/shared/themeVariables';
// import FooterCreator from '~/components/Layout/FooterCreator';
const FooterCreator = dynamic(() =>
  import('~/components/Layout/FooterCreator'),
);
const roles = Config.getRoles();
const PHOTO_CART_HEIGHT = 120;
const SEARCH_HEADER_HEIGHT = 70;

@withI18next()
@withCookies
@withRouter
class Layout extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { router } = props;
    return {
      paddingTopMain:
        router.pathname !== '/help-page'
          ? Layout.calculatePaddingTopMainContent(props, state)
          : Layout.calculatePaddingTopOfFAQContent(props, state),
    };
  }

  static calculatePaddingTopMainContent(props, state) {
    const {
      // header,
      toggleSlider,
      subCategory = false,
      photosSlider,
      isTrial,
      profile,
      router,
      // isAuthenticated,
      // countdown,
      // isCountdownActive,
      // currentLang,
      // isTopBannerVisible,
    } = props;
    let { isActiveDiscount, showSubCategory } = state;
    isActiveDiscount = false;
    // const isHomePage = router.pathname === '/index' || router.pathname === '/';
    // const isPremiumPage = router.pathname === '/premium';
    const isFAQ = router.pathname === '/help-page';
    const isCreatorPage =
      router.pathname === '/sell-your-content' ||
      router.pathname === '/submission-guideline';
    // const isBannerDisplayed =
    //   isTopBannerVisible &&
    //   isHomePage &&
    //   Config.isCreatorAvailable(currentLang) &&
    //   topBannerScroll;
    // const isDiscountDisplayed =
    //   profile.role !== roles.PREMIUM && countdown && !isPremiumPage && !isFAQ;
    const isDiscountDisplayed = false;

    let base = 0; // height of menu header
    if (state.isTabletS) {
      base += 58;
    } else {
      base = 0;
    }
    if (!isFAQ && !isCreatorPage && !Config.isDesignAC())
      base += SEARCH_HEADER_HEIGHT;
    if (toggleSlider && photosSlider.length) {
      base = base - SEARCH_HEADER_HEIGHT + PHOTO_CART_HEIGHT;
    }
    base += showSubCategory && subCategory ? 30 : 0;
    base +=
      profile && profile.role === roles.PREMIUM
        ? 0
        : isTrial || isActiveDiscount
        ? 60
        : 0;
    // if (isBannerDisplayed) {
    //   base += 90; // Height of top banner
    // }
    if (isDiscountDisplayed) {
      base += 60; // Height of discount banner
    }
    if (Config.isDesignAC()) {
      if (state.isTabletS) {
        base += 0;
      } else {
        base += 70;
      }
    }
    return base;
  }

  static calculatePaddingTopOfFAQContent(props, state) {
    const {
      // header,
      toggleSlider,
      subCategory = false,
      photosSlider,
      isTrial,
      profile,
      // router,
      // isAuthenticated,
      // countdown, // TODO: remove
      // isCountdownActive,
    } = props;
    let { isActiveDiscount, showSubCategory } = state;
    isActiveDiscount = false;
    // const isHomePage = router.pathname === '/index' || router.pathname === '/';
    // const isPremiumPage = router.pathname === '/premium';

    let base = 58; // height of menu header
    if (toggleSlider && photosSlider.length) {
      base += PHOTO_CART_HEIGHT;
    }
    base += showSubCategory && subCategory ? 30 : 0;
    base +=
      profile.role === roles.PREMIUM ? 0 : isTrial || isActiveDiscount ? 60 : 0;
    return base;
  }

  constructor(props) {
    super(props);
    const { subCategory, isActiveDiscount } = this.props;
    this.state = {
      showSubCategory: subCategory,
      paddingTopMain: 0,
      isActiveDiscount,
      // topBannerScroll: true,
      isShowHeaderExternal: true,
      showJapanNotice: false,
      isTabletS: false,
    };
    this.checkIsTabletS = this.checkIsTabletS.bind(this);
    this._checkIsTabletS = debounce(this.checkIsTabletS, 200);
    this.calculateSubCategoryState = this.calculateSubCategoryState.bind(this);
    this._calculateSubCategoryState = debounce(
      this.calculateSubCategoryState,
      200,
    );
    // this.props.initCountdown();
  }

  componentDidMount() {
    const { profile } = this.props;
    this.calculateSubCategoryState();
    this.checkIsTabletS();
    window.addEventListener('resize', this._calculateSubCategoryState);
    window.addEventListener('resize', this._checkIsTabletS);
    window.addEventListener('scroll', this.listenToScroll);

    this.props.updateIsSurvey();
    this.props.checkIsActiveEditorDownload(profile.role);
    this.checkIsInJapan();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._calculateSubCategoryState);
    window.removeEventListener('resize', this._checkIsTabletS);
    window.removeEventListener('scroll', this.listenToScroll);
  }

  checkIsTabletS() {
    if (window.innerWidth <= themeVariables.breakpoints.tabletS.maxWidth) {
      this.setState({ isTabletS: true });
    } else {
      this.setState({ isTabletS: false });
    }
    if (window.innerWidth <= 599) {
      this.props.updateShowFilterOnHeader(false);
    }
  }

  calculateSubCategoryState() {
    const showSubCategory = window.innerWidth >= 1200;
    this.setState({ showSubCategory });
  }

  checkIsInJapan = () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const isInJapan = timezoneOffset === -540; // Japan is in timezone +9
    this.setState({
      showJapanNotice:
        !Config.isDesignAC() && isInJapan && !LocalStorage.hideJapanNotice,
    });
  };

  handleCloseJapanNotice = () => {
    this.setState({
      showJapanNotice: false,
    });
  };

  listenToScroll = () => {
    // const winScroll =
    //   document.body.scrollTop || document.documentElement.scrollTop;
    // Search filter Illust: handle for search filter IllustAC
    if (Config.isIllustAC()) {
      this.props.closeAllFilter();
    }
    // if (winScroll > 500) {
    //   this.setState({
    //     topBannerScroll: false,
    //     // isShowHeaderExternal: false,
    //   });
    // } else {
    //   this.setState({
    //     topBannerScroll: true,
    //     // isShowHeaderExternal: true,
    //   });
    // }
  };

  onSearchSubmit = (values) => {
    if (
      !values.keyword &&
      !values.category &&
      !values.imageTitle &&
      !values.imageId &&
      values.color === 'all' &&
      !values.creatorName
    ) {
      return toast.warn(this.props.t('search_message_not_input'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }

    values = { ...this.props.query, ...values };
    let asPath = 'page=1';
    forIn(values, (value, key) => {
      if (
        value &&
        key !== 'page' &&
        key !== 'is_tag' &&
        key !== 'clientIp' &&
        key !== 'keyword'
      ) {
        asPath += `&${key}=${value}`;
      }
    });

    LocalStorage.otherServices = null;

    let pageURL = `/search/${encodeURIComponent(values.keyword)}?${asPath}`;
    if (this.props.currentLang !== 'en' || this.props.isLocaleInPath) {
      pageURL = `/${this.props.currentLang}${pageURL}`;
    }
    // let ModelRelease = {};
    // if (values.modelRelease) {
    //   switch (values.modelRelease) {
    //     case '0':
    //       ModelRelease = { model: 2, property: 0 };
    //       break;
    //     case '1':
    //       ModelRelease = { model: 0, property: 2 };
    //       break;
    //     case '2':
    //       ModelRelease = { model: 0, property: 0 };
    //       break;
    //   }
    // }
    const { countSearch } = this.props.layout;
    this.props.updateDataCountSurvey({
      countSearch: countSearch + 1,
    });

    const { searchPhoto } = this.props;
    searchPhoto({
      ...values,
      page: 1,
      keyword: values.keyword,
      excludeKeyword: values.excludeKeyword,
      category: values.category,
      excludeCategory: values.excludeCategory,
      creatorName: values.creatorName,
      excludeCreatorName: values.excludeCreatorName,
      color: values.color || 'all',
      modelCount: values.modelCount,
      modelAge: values.modelAge,
      isFromSearchHeader: true,
      // fileType: values.imageType,
      imageType: values.imageType,
      imageId: values.imageId,
      orderBy: values.orderBy,
      shape: values.shape,
      title: values.imageTitle,
      modelRelease: values.modelRelease,
      image_token: null,
    });
    I18nRouter.push(
      {
        pathname: `/search/${encodeURIComponent(values.keyword)}`,
        locale: this.props.currentLang,
        query: {
          ...values,
          page: 1,
          keyword: values.keyword,
          excludeKeyword: values.excludeKeyword,
          category: values.category,
          excludeCategory: values.excludeCategory,
          creatorName: values.creatorName,
          excludeCreatorName: values.excludeCreatorName,
          color: values.color || 'all',
          modelCount: values.modelCount,
          modelAge: values.modelAge,
          isFromSearchHeader: true,
          // fileType: values.imageType,
          imageType: values.imageType,
          imageId: values.imageId,
          orderBy: values.orderBy,
          shape: values.shape,
          title: values.imageTitle,
          modelRelease: values.modelRelease,
          image_token: null,
        },
      },
      pageURL,
      { shallow: true },
    );
  };

  render() {
    const {
      currentLang,
      categories,
      header,
      t,
      token,
      serviceType,
      profile,
      isAuthenticated,
      onLogout,
      subCategory,
      toggleSliderFunc,
      // photosSlider,
      // cookie,
      // patterns,
      // isActiveDiscount,
      router,
      // countdown,
      // isCountdownActive,
      // isTopBannerVisible,
    } = this.props;
    const { showJapanNotice } = this.state;
    const isHomePage = router.pathname === '/index' || router.pathname === '/';
    const isAboutUs = router.pathname === '/about-us';
    const isFAQ = router.pathname === '/help-page';
    const isPremiumPage = router.pathname === '/premium';
    const isCreatorPage =
      router.pathname === '/sell-your-content' ||
      router.pathname === '/submission-guideline';
    // const isSearchResultPage = router.pathname === '/search-result';
    const isAILandingPage = router.pathname === '/ai-projects';
    const wasPopupBannerDisplayed =
      LocalStorage.popupBannerDate === new Date().getDate();
    // const isBannerDisplayed =
    //   isTopBannerVisible &&
    //   isHomePage &&
    //   Config.isCreatorAvailable(currentLang) &&
    //   this.state.topBannerScroll;
    // * Use to toggle discount banner
    // const isDiscountDisplayed =
    //   profile.role !== roles.PREMIUM &&
    //   isCountdownActive &&
    //   (isPremiumPage || isSearchResultPage);
    const isShowHeaderExternal = this.state.isShowHeaderExternal;
    const { layout } = this.props;
    const {
      isPhotoCartOpen,
      photosInCart,
      // isSurvey,
      isShowSearchForm,
      isShowFilterOnHeader,
    } = layout;
    // console.log('photosInCart ne hihi', photosInCart);
    return (
      <>
        {/* {!isSurvey && (Config.isPhotoAC() || Config.isIllustAC()) && (
          <Survey profile={profile} t={t} />
        )} */}

        {/* {isBannerDisplayed && <Banner />} */}
        {/* {isHomePage &&
          !wasPopupBannerDisplayed &&
          Config.isCreatorAvailable(currentLang) && <PopupBanner />} */}
        <div className="container-wrapper">
          {/* {isDiscountDisplayed && <HeaderCountdownDiscount />} */}

          {/* {isShowHeaderExternal && (
            <ExternalLinksHeader
              isBannerDisplayed={isBannerDisplayed}
              pathName={router.pathname}
              // isDiscountDisplayed={isDiscountDisplayed}
            />
          )} */}

          {!isCreatorPage && !isAILandingPage && (
            <MenuHeader
              // isBannerDisplayed={isBannerDisplayed}
              // isDiscountDisplayed={isDiscountDisplayed}
              isShowHeaderExternal={false}
            />
          )}
          {/* <MenuHeader isBannerDisplayed={isBannerDisplayed} /> */}

          {!isFAQ && !isCreatorPage && !isAILandingPage && (
            <SearchHeader
              isAuthenticated={isAuthenticated}
              profile={profile}
              categories={categories}
              onLogout={onLogout}
              subCategory={subCategory}
              toggleSliderFunc={toggleSliderFunc}
              token={token}
              show={header}
              onSearchSubmit={this.onSearchSubmit}
              isPremiumPage={isPremiumPage}
              // isDiscountDisplayed={isDiscountDisplayed}
              // isBannerDisplayed={isBannerDisplayed}
              isShowHeaderExternal={isShowHeaderExternal}
            />
          )}

          {isPhotoCartOpen && photosInCart.length > 0 && (
            <PhotoCart
              isShowHeaderExternal={isShowHeaderExternal}
              // isBannerDisplayed={isBannerDisplayed}
              isShowFilterOnHeader={isShowFilterOnHeader}
            />
          )}
          {/* {!isFAQ && <SearchBar />} */}
          <main
            className={clsx('main-content', {
              'show-photo-cart': isPhotoCartOpen && !isAILandingPage,
              'show-search-form': isShowSearchForm && !isAILandingPage,
              'ai-lading-page': isAILandingPage,
            })}
            style={
              {
                // paddingTop: isAILandingPage
                //   ? 0
                //   : isPhotoCartOpen
                //   ? isHomePage && isShowSearchForm && this.state.isTabletS
                //     ? this.state.paddingTopMain + 98
                //     : this.state.paddingTopMain + 100
                //   : isHomePage && isShowSearchForm && this.state.isTabletS
                //   ? this.state.paddingTopMain - 58
                //   : this.state.paddingTopMain,
              }
            }
          >
            {this.props.children}
          </main>
          {/* <NotificationComponent /> */}
          {/* <BrowseImagesCategory categories={categories} t={t} lng={currentLang} />
        <div style={{ borderBottom: '2px solid #4b4747' }} />
        <PatternOnPage patterns={patterns} currentLanguage={currentLang} /> */}
          {/* <AIBanner /> */}
          {/* {!isAboutUs && <SectionCompanyIntro />} */}
          {!isAboutUs && !isCreatorPage && !isAILandingPage && (
            <SectionTotalDownload />
          )}
          {isAILandingPage && <SectionFooterAILandingPage />}
          {/* {isAboutUs && <div style={{ borderBottom: '1px solid #d8d8d8' }} />} */}
          {isCreatorPage && (
            <FooterCreator
              currentLang={currentLang}
              serviceType={serviceType}
              profile={profile}
              isAuthenticated={isAuthenticated}
              t={t}
            />
          )}
          {!isCreatorPage && (
            <Footer
              currentLang={currentLang}
              serviceType={serviceType}
              profile={profile}
              isAuthenticated={isAuthenticated}
              t={t}
            />
          )}

          {/* <div style={{ borderBottom: '1px solid #d8d8d8' }} /> */}
          {/* <GroupWebsites t={t} token={token} /> */}
          <LoadingContainer />
          {/* <LoginModalContainer path={this.props.path} /> */}
          <ErrorMessageModalContainer />
          <CustomErrorMessage />
          {/* <ScrollTop /> */}
          {/* <TranslationForm /> */}
          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            className="layout-toast-container"
            bodyClassName="layout-toast-body"
            progressClassName={`layout-toast-progress ${Config.serviceType}`}
          />
          <RegisteredUsersModal />
          <PhotoSearchModal />
          {showJapanNotice && (
            <JapanSiteNotice onClose={this.handleCloseJapanNotice} />
          )}
          <a
            href="/photos/similar"
            rel="nofollow"
            style={{ display: 'none' }}
            aria-hidden="true"
          >
            honeypot link
          </a>
          <style jsx global>
            {`
              .container-wrapper {
                width: 100%;
                overflow: hidden;
              }

              .main-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: ${SEARCH_HEADER_HEIGHT}px;
              }
              .main-content.show-photo-cart.show-search-form {
                padding-top: ${SEARCH_HEADER_HEIGHT + PHOTO_CART_HEIGHT}px;
              }
              .main-content.ai-lading-page {
                padding-top: 0;
              }
              @media (max-width: ${themeVariables.breakpoints.mobile
                  .maxWidth}px) {
                .container-wrapper {
                  overflow: unset;
                }
                .main-content {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  overflow: hidden;
                  padding-top: 0;
                }
                .main-content.show-photo-cart.show-search-form {
                  padding-top: 0;
                }
              }
              #nprogress {
                pointer-events: none;
              }
              #nprogress .bar-1 {
                background: #ffa823;
                position: fixed;
                z-index: 1031;
                top: 0;
                left: 0;
                width: 100%;
                height: 3px;
              }
              #nprogress .bar-illust {
                background: #d73a4e;
                position: fixed;
                z-index: 1031;
                top: 0;
                left: 0;
                width: 100%;
                height: 3px;
              }
              #nprogress .bar-sil {
                background: #41b9b8;
                position: fixed;
                z-index: 1031;
                top: 0;
                left: 0;
                width: 100%;
                height: 3px;
              }
              #nprogress .bar-photo {
                background: #509bdc;
                position: fixed;
                z-index: 1031;
                top: 0;
                left: 0;
                width: 100%;
                height: 3px;
              }
              #nprogress .peg {
                display: block;
                position: absolute;
                right: 0;
                width: 100px;
                height: 100%;
                box-shadow: 0 0 10px #ffa823, 0 0 5px #ffa823;
                opacity: 1;
                -webkit-transform: rotate(3deg) translate(0px, -4px);
                -ms-transform: rotate(3deg) translate(0px, -4px);
                transform: rotate(3deg) translate(0px, -4px);
              }

              .nprogress-custom-parent {
                overflow: hidden;
                position: relative;
              }

              .nprogress-custom-parent #nprogress .bar {
                position: absolute;
              }

              span.visually-hidden {
                display: none;
              }
            `}
          </style>
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  header: PropTypes.bool,
  subCategory: PropTypes.bool,
  isActiveDiscount: PropTypes.bool,
};

Layout.defaultProps = {
  header: true,
  subCategory: false,
  isActiveDiscount: true,
};

const mapStateToProps = createStructuredSelector({
  cookie: (state) => state.noticeCookie,
  categories: makeSelectCategories(),
  currentLang: makeSelectCurrentLang(),
  serviceType: makeSelectServiceType(),
  profile: makeSelectProfile(),
  link: makeSelectLink(),
  isAuthenticated: makeSelectIsAuthenticated(),
  // toggleSlider: makeSelectToggleSlider(),
  // photosSlider: makeSelectPhotosSlider(),
  trial: makeSelectTrial(),
  isTrial: makeSelectIsTrial(),
  token: makeSelectToken(),
  patterns: makeSelectPatterns(),
  // countdown: makeSelectCountdownDiscount(),
  isCountdownActive: makeSelectIsCountdownActive(),
  // countdownDiscount: state => state.pricePremium.result.countdownDiscount.remainingDurationInMilliseconds,
  isTopBannerVisible: makeSelectIsTopBannerVisible(),
  isLocaleInPath: makeSelectIsLocaleInPath(),
  query: makeSelectQuery(),
  layout: selectLayout,
});
const mapDispatchToProps = (dispatch) => ({
  onLogout: () => {
    dispatch(logout());
    I18nRouter.push({ pathname: '/' });
  },
  updateIsSurvey: () => dispatch(updateIsSurvey()),
  updateDataCountSurvey: (data) => dispatch(updateDataCountSurvey(data)),
  checkIsActiveEditorDownload: (data) =>
    dispatch(checkIsActiveEditorDownload(data)),
  searchPhoto: (data) => dispatch(searchPhoto(data)),
  closeAllFilter: () => dispatch(closeAllFilter()),
  updateShowFilterOnHeader: (value) =>
    dispatch(updateShowFilterOnHeader(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
